<template>
    <section class="content">
        <header class="content__title">
            <h1>Dashboard</h1>
            <small>Welcome to the Scripture Union Mobile App Dashboard!</small>
        </header>

        <div class="row quick-stats">
            <div class="col-sm-6 col-md-3">
                <div class="quick-stats__item bg-blue">
                    <div class="quick-stats__info">
                        <h2>{{ totals.subscribers }}</h2>
                        <small>Total Subscribers</small>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="quick-stats__item bg-amber">
                    <div class="quick-stats__info">
                        <h2>{{ totals.users }}</h2>
                        <small>Total Users</small>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="quick-stats__item bg-purple">
                    <div class="quick-stats__info">
                        <h2>{{ totals.devotionals }}</h2>
                        <small>Total Devotionals</small>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="quick-stats__item bg-red">
                    <div class="quick-stats__info">
                        <h2>{{ totals.donations }}</h2>
                        <small>Total Donations</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <LatestDonations />
            </div>
            <div class="col-md-12">
                <LatestPayments />
            </div>
            <div class="col-md-6">
                <LatestNews />
            </div>
            <div class="col-md-6">
                <LatestUsers />
            </div>
        </div>
        
    </section>
</template>

<script>
import firebase from "firebase/app"
export default {
    data(){
        return {
            totals: {
                users: 0,
                subscribers: 0,
                devotionals: 0,
                donations: 0,
            },
            isLoading: false,
            isProcessing: false,
            results: null,
            news: [],
            donations: [],
        }
    },
    async created(){
        this.statistics()
    },
    components: {
        "LatestNews": () => import("@/components/LatestNews.vue"),
        "LatestUsers": () => import("@/components/LatestUsers.vue"),
        "LatestPayments": () => import("@/components/LatestPayments.vue"),
        "LatestDonations": () => import("@/components/LatestDonations.vue"),
    },
    methods: {
        async statistics(){
            const stats = await firebase.firestore().collection("statistics").doc("totals").get()
            this.totals = stats.data()
        },
        async subsTotal(){
            this.isProcessing = true
            const totalSubscribers = firebase.functions().httpsCallable("totalSubscribers")
            const response = await totalSubscribers()
            this.results = response.data
            console.log("response.data", response.data);
            this.isProcessing = false
        },
    }
}
</script>

